.popover {
    background-color: var(--popover-bg-color);
    border-color: var(--popover-border-color);
}
  
.bs-popover-bottom {
    > .popover-arrow {
  
        &::before {
            border-bottom-color: var(--popover-outerarrow-color);
        }
  
        &::after {
            border-bottom-color: var(--popover-arrow-color);
        }
    }
}
  
.popover-body {
    background-color: var(--popover-body-bg-color);
    color: var(--popover-body-text-color)
}
